.RichEditor-root {
  background: #fff;
  font-size: 14px;
  /* padding: 1rem; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.RichEditor-editor {
  /* border-top: 1px solid #323130; */
  cursor: text;
  font-size: 16px;
  /* margin-top: 10px; */
  padding: 1rem;
  flex: 1;
  min-height: 12rem;
}

.controls {
  padding: 1rem;
  border-bottom: 1px solid #ebebeb;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 1rem;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  /* font-family: 'Hoefler Text', 'Georgia', serif; */
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .RichEditor-textcenter {
  text-align: center;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  /* font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace; */
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  /* font-family: 'Helvetica', sans-serif; */
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #323130;
  cursor: pointer;
  margin-right: 10px;
  padding: 2px 3px;
  display: inline-block;
  border-bottom: 2px solid #fff;
}

.RichEditor-activeButton {
  border-color: #005a9e;
  /* color: #005a9e; */
}