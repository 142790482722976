.options .option {
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;
  background: #fff;
}

.option:hover {
  background: #c7e0f4;
}

.lower {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

section.overflow-x {
  overflow-x: scroll;
}

.project-title ::placeholder {
  font-size: 30px;
}

.project-title {
  height: auto;
}

.show-more-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-method {
  margin-bottom: 8px;
}

.select-method button {
  margin-right: 8px;
}

.eliminated-wrapper {
  margin: 8px 0px;
}

.eliminated-list p {
  margin: 4px 0px;
}

.eliminated-list {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.eliminated-list label {
  margin-right: 8px;
}

.eliminated-list .eliminated-obj, .eliminated-list .eliminated-alt {
  padding: 4px 8px 4px 4px;
  background: #fff;
  border-radius: 2px;
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.block {
  /* min-width: 240px; */
  /* width: 300px; */
  margin-right: 2rem;
}

.legend {
  display: flex;
}

.legend .cell {
  height: 32px;
  /* width: 120px; */
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-right: 4px;
  padding: 0 16px;
}

.vert-text {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  font-size: 12px;
}

.save {
  padding-left: 32px;
}

.instruction, .max-width {
  max-width: 1000px;
}

.instruction p.emphasize {
  padding: 16px;
  background: #ebebeb;
  border-left: 4px solid #c7e0f4;
}

.fix-table {
  table-layout: fixed;
  min-width: 500px !important;
}

.fix-table tr td {
  width: 100px;
  height: 30px;
  vertical-align: middle;
}

.fix-table tr td:first-child {
  width: 200px;
}

.center {
  text-align: center;
}

.larger {
  font-size: 22px;
}

.large-btn {
  font-size: 22px;
  height: 40px;
}

.full-view {
  min-height: 100vh;
  overflow: scroll;
}

.substep-wrapper {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.guide .material {
  /* background-color: orchid; */
  flex: 1;
  padding: 32px;
  height: calc(100% - 140px);
}

.guide .navigation {
  width: 100%;
  height: 140px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: orange; */
}

.block-list {
  margin: 0;
  padding: 0;
}

.block-list li {
  list-style: none;
  padding: 6px;
  background: #ebebeb;
  border: 1px solid #333;
  margin-bottom: 8px;
  width: 400px;
  max-width: 100%;
}

.demo-table {
  font-size: 24px;
}

.demo-table .alt-cell, .demo-table .obj-cell {
  background: #ebebeb;
  border: 1px solid #333;
}

.demo-table .data-cell {
  background: #fff;
  border: 1px solid #333;
}

.demo-table .obj-cell {
  width: 400px;
}
.demo-table .alt-cell, .demo-table .data-cell {
  width: 160px;
  text-align: center;
}

.demo-table .good {
  background-color: #66bb6a;
  color: #fff;
}

.demo-table .bad {
  background-color: #e57373;
  color: #fff;
}

.demo-table .middle {
  background: #ffee58;
}

.link {
  color: #fff;
}

.link:hover {
  color: #005a9e;
}