.home .home-title {
  /* color: white; */
  font-size: 42px;
  margin-bottom: 16px;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.home-sub-title {
  /* color: #fff; */
  font-size: 24px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.login-panel {
  background-color: #edebe9;
}

.banner-panel {
  background-color: #323130;
  color: #fff;
  padding: 32px;
}

.form {
  width: 80%;
  margin: 0 auto;
}

.sign-in-button, .sign-up-button {
  width: 100%;
  margin-top: 1rem;
}

.switch-form {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.message-placeholder {
  /* height: 32px; */
  margin-bottom: 1rem;
}