.ct th {
  text-align: left;
  font-weight: 600;
  padding: 8px;
}

td.center {
  text-align: center;
}

table.ct {
  /* min-width: 100%; */
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 2rem;
  margin-top: 3rem;
  /* background: #faf9f8; */
}

table.ct-fixed {
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 2rem;
  margin-top: 1rem;
  /* background: #faf9f8; */
}

/* table.ct  th, table.ct td, table.ct-fixed  th, table.ct-fixed td { */
  /* background: #fff; */
  /* border-top: 1px solid; */
  /* border-bottom: 1px solid; */
/* } */

.ct tr, td, th {
  position: relative;
  /* padding: 4px; */
}

table.ct tbody tr {
  background: #fff;
  border-bottom: 4px solid #ebebeb;
}

.add-node {
  position: absolute;
  left: -32px;
  bottom: -32px;
  z-index: 2;
  background: none !important;
}

.add-alt {
  position: absolute;
  top: -32px;
  right: -32px;
  z-index: 2;
  background: none !important;
}

.add-spacer {
  position: absolute;
  left: -24px;
  bottom: -16px;
  z-index: 2;
  background: none !important;
  color: #a19f9d;
  opacity: 0.5;
  transition: all 0.1s ease-in-out;
}

.add-spacer:hover {
  opacity: 1;
}

.spacer {
  height: 20px;
  background: none;
}