.dashboard {
    position: relative;
}

.section-header {
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
}

.actions {
    padding: 1rem;
}
  
.grid {
    /* padding: 1rem; */
    width: 100%;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid a {
    text-decoration: none;
    color: inherit;
}

.favorite-toggle {
    position: absolute;
    z-index: 1;
}

.create-project-modal {
    width: 600px;
    max-width: 100%;
}

.create-project-modal h3 {
    padding: 20px;
    background: #eaeaea;
    margin: 0;
    border-bottom: 2px solid #1878d4;
}

.create-project-content {
    padding: 20px;
}

.create-project-button {
    margin-top: 10px;
}