.full-height {
  height: 100%;;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.scroll-x {
  overflow-x: auto;
}

.flex-1 {
  flex: 1
}

.flex-2 {
  flex: 2
}

.flex-3 {
  flex: 3
}

.flex-4 {
  flex: 4
}

.flex-5 {
  flex: 5
}

/* margin shortcuts */
.mb-16 {
  margin-bottom: 16px;
}